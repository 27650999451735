import React from "react";
import { motion } from "framer-motion";

function Contact() {
  return (
    <section className="contact">
      <motion.h2
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Contact Us
      </motion.h2>
      <div className="contact-content">
        <motion.div
          className="contact-details"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h3>Get in Touch</h3>
          <div className="contact-info">
            <h4>Phone</h4>
            <p>+91 98405 32255</p>
            <h4>Email</h4>
            <p>info@quixentapps.com</p>
            <h4>Customer Support</h4>
            <p>
              Our support team is available around the clock to address any
              concerns or queries you may have.
            </p>
            <h4>Feedbacks & Suggestions</h4>
            <p>
              We value your feedback and suggestions. Your input is crucial in
              shaping the future of our company.
            </p>
            <h4>United States of America</h4>
            <p>
              <strong>Address:</strong> 34785 Pickford dr, Farmington hills MI
              -48335
            </p>
            <p>
              <strong>Phone:</strong> +1 84823 77695
            </p>
          </div>
        </motion.div>
        <motion.form
          className="contact-form"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <input type="text" placeholder="Subject" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit">Send Message</button>
        </motion.form>
      </div>
    </section>
  );
}

export default Contact;