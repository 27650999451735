import React from "react";
import { motion } from "framer-motion";

function StaffingServices() {
  return (
    <section className="staffing-services">
      <motion.div
        className="staffing-content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="staffing-text-top">
          <motion.img
            src="https://www.shutterstock.com/image-photo/three-professional-happy-diverse-international-600nw-2353374167.jpg"
            alt="Staffing Services"
            className="staffing-image"
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
          <div className="staffing-text-top-content">
            <h2>Staffing</h2>
            <p>
              We are your trusted partner in recruitment, offering tailored staffing solutions to meet your business needs and empower job seekers to find their ideal roles. Our dedicated team leverages expertise, technology, and a vast network to deliver exceptional results.
            </p>

            <h3>Our Services</h3>
            <ul>
              <li><strong>Permanent Staffing:</strong> Finding the perfect long-term fit for your company’s needs.</li>
              <li><strong>Temporary & Contract Staffing:</strong> Flexible workforce solutions to meet your short-term demands.</li>
              <li><strong>Executive Search:</strong> Identifying and attracting top-level professionals for leadership roles.</li>
              <li><strong>Industry-Specific Recruitment:</strong> Expertise in various industries, including IT, healthcare, finance, engineering, and more.</li>
            </ul>
          </div>
        </div>
        <div className="staffing-text-bottom">
          <h3>For Employers</h3>
          <p>
            Hiring the right talent is crucial to your company's success. Our expert recruiters use industry insights, cutting-edge technology, and a vast network to find candidates who not only meet job requirements but also align with your company culture.
          </p>

          <h3>For Job Seekers</h3>
          <p>
            Your career journey starts here! We provide access to exclusive job opportunities, resume guidance, and interview coaching to help you land your dream job. Let us connect you with top employers who value your skills and experience.
          </p>

          <h3>Why Choose Us?</h3>
          <ul>
            <li>A dedicated team with extensive recruitment expertise.</li>
            <li>A vast network of qualified candidates and top employers.</li>
            <li>A streamlined hiring process with customized solutions.</li>
            <li>Commitment to diversity, inclusion, and equal opportunities.</li>
          </ul>
        </div>
      </motion.div>
    </section>
  );
}

export default StaffingServices;