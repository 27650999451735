import React from "react";
import { motion } from "framer-motion";

function About() {
  return (
    <section className="about">
      <div className="about-content">
        <motion.div
          className="about-text"
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2>About Us</h2>
          <p>
            Quixent is your trusted partner for digital transformation, offering
            dynamic websites, mobile apps, and cloud solutions. Our expert team
            provides high-quality services like web development, SEO, cloud
            migration, and business consulting to help businesses thrive.
            Committed to excellence, we deliver tailored, affordable solutions
            to enhance your online presence and achieve your goals.
          </p>
        </motion.div>
        <motion.img
          src="https://www.pngmart.com/files/About-Us-PNG-Isolated-File.png"
          alt="About Us"
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        />
      </div>
    </section>
  );
}

export default About;