import React from "react";

function Store() {
  const redirectToPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/dev?id=5363834578851195995";
  };

  const redirectToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/us/app/quixent/id123456789";
  };

  return (
    <section className="store">
      <h2>Explore Our Apps</h2>
      <div className="store-buttons">
        <button onClick={redirectToPlayStore} className="store-button">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzEy8HTVhe6kewSa_-x4TerF5isgiP1-DByQ&s"
            alt="Get it on Google Play"
            className="store-badge"
          />
        </button>
        <button onClick={redirectToAppStore} className="store-button">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD0-VifLPKywOa4lSu4LWfOfsBb-lNBJrrRw&s"
            alt="Download on the App Store"
            className="store-badge"
          />
        </button>
      </div>
    </section>
  );
}

export default Store;