import React from "react";
import { motion } from "framer-motion";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <motion.section
      className="privacy"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <div className="privacy-content">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Privacy Policy
        </motion.h1>
        <p className="privacy-date"><strong>Effective Date:</strong> 16-03-2024</p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At Quixent Apps, we respect your privacy and are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data. By using our website, you consent to the practices described in this policy.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          We collect information that you provide directly when you register, subscribe, or contact us. This may include your name, email address, and phone number. Additionally, we gather data such as your IP address, browser type, and the pages you visit to improve our services. We also use cookies and tracking technologies to enhance user experience and analyze website performance.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          The information we collect is used to operate, maintain, and improve our website. We utilize this data to personalize user experience, provide customer support, and send marketing materials if you have opted in. Our goal is to enhance our services while ensuring transparency and security.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          We do not sell, trade, or rent your personal information. However, we may share your data with trusted service providers who assist in operating our website and services. We may also disclose information if required by law or to protect our rights and the safety of our users.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.7 }}
        >
          Our website may use cookies and other tracking technologies to enhance user experience and analyze trends. These tools help us understand user behavior and improve website functionality. You can manage your cookie preferences in your browser settings.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          We may provide links to third-party websites for additional resources or services. However, we are not responsible for their privacy practices. We encourage users to review the privacy policies of external sites before providing any personal information.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.9 }}
        >
          To protect your data, we implement security measures to prevent unauthorized access, loss, or misuse. While we take all reasonable precautions, no internet transmission is entirely secure. We advise users to take necessary steps to safeguard their own personal information.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.0 }}
        >
          You have rights regarding your personal data, including the ability to access, update, or request the deletion of your information. If you have any concerns about your data, please contact us, and we will assist you in exercising your rights.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.1 }}
        >
          We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We encourage users to review this page regularly to stay informed about how we handle their data.
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.2 }}
        >
          If you have any questions or concerns regarding this Privacy Policy, feel free to contact us at <strong>+91 98405 32255</strong>.
        </motion.p>
      </div>
    </motion.section>
  );
}

export default PrivacyPolicy;