import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import About from "./components/About";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Store from "./components/Store";
import Footer from "./components/Footer";
import "./App.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Recruitment from "./components/Recruitment";

function App() {
  return (
    <div className="App">
      <div id="home"></div>
      <Header />
      <Hero />
      <section id="store">
        <Store />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="recruitment">
        <Recruitment />
      </section>
      <section id="testimonials">
        <Testimonials />
      </section>
      <section id="privacy">
      <PrivacyPolicy />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default App;