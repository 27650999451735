import React, { useState } from "react";
import { motion } from "framer-motion";
import logo from "./logo_blue_aqua.png";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="header-container">
      <motion.header
        className="header"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="header-content">
          <img
            src={logo}
            alt="Quixent Logo"
            className="logo"
          />
          <nav className={isNavOpen ? "nav-open" : ""}>
            <ul className="nav-list">
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    setIsNavOpen(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  href="#services"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#about"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#recruitment"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  Staffing
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#testimonials"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  Testimonials
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#privacy"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                  onClick={() => setIsNavOpen(false)}
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <div className="auth-buttons">
            <button className="signup-btn">SignUp</button>
            <button className="login-btn">Login</button>
          </div>
          <div className="hamburger" onClick={toggleNav}>
            {isNavOpen ? "✖" : "☰"}
          </div>
        </div>
      </motion.header>
      <a
        href="https://api.whatsapp.com/send?phone=919840532255"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
        />
      </a>
    </div>
  );
}

export default Header;