import React from "react";
import { motion } from "framer-motion";

function Services() {
  const servicesData = [
    {
      title: "Website Development",
      description:
        "State-of-the-art, mobile-friendly websites tailored for your business, optimized for SEO and scalability.",
      image:
        "https://png.pngtree.com/png-clipart/20220307/original/pngtree-design-vector-web-development-png-image_7424322.png",
    },
    {
      title: "Native Apps Development",
      description:
        "High-performance Android/iOS apps with seamless UX, built using Swift, Kotlin, and React Native.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYYB2Z7nB5nsDYTcWUlOJP19YFN8lZFd3WfA&s",
    },
    {
      title: "Website Migration",
      description:
        "Seamless website migration with minimal downtime, ensuring data security and functionality.",
      image: "https://cdn-icons-png.flaticon.com/512/9954/9954551.png",
    },
    {
      title: "AWS/Azure Cloud Migration/Maintenance",
      description:
        "Secure cloud migration and maintenance for AWS/Azure, with cost optimization and scalability.",
      image:
        "https://hystax.com/wp-content/uploads/2022/10/live-cloud-migration-to-AWS.png",
    },
    {
      title: "Data Maintenance",
      description:
        "Comprehensive data management, including cleansing, backup, and recovery for accuracy and security.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBpOHF-BMwc9owh3WLssyA0pxosLulCycUCg&s",
    },
    {
      title: "Business Consultations",
      description:
        "Expert strategies to optimize operations, enhance productivity, and drive growth.",
      image:
        "https://png.pngtree.com/illustrations/20190321/ourmid/pngtree-financial-financial-management-business-advisory-png-image_28757.jpg",
    },
    {
      title: "Payroll/HR Services",
      description:
        "Streamlined payroll and HR solutions for compliance, automation, and workforce management.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfiGRw5qxWvJGEjX9jcsIbku4gycNg0kg6gA&s",
    },
    {
      title: "SEO Optimization",
      description:
        "Proven SEO strategies to boost visibility, rankings, and organic traffic.",
      image:
        "https://static.vecteezy.com/system/resources/previews/016/390/577/non_2x/search-engine-optimization-free-png.png",
    },
    {
      title: "Digital Marketing",
      description:
        "Tailored digital marketing to engage audiences via social media, email, and more.",
      image:
        "https://static.vecteezy.com/system/resources/previews/009/312/714/non_2x/3d-render-target-marketing-digital-marketing-png.png",
    },
    {
      title: "Ads/Campaigns",
      description:
        "Targeted ad campaigns on Google, Facebook, and Instagram for maximum ROI.",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/022/595/011/small/3d-illustration-of-advertising-checklis-png.png",
    },
    {
      title: "Staffing",
      description:
        "Tailored staffing solutions to recruit skilled professionals for your business needs.",
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/011/299/670/small_2x/businessman-and-company-staff-posing-character-3d-character-illustration-png.png",
    },
    {
      title: "Idea Brainstorming",
      description:
        "Creative workshops to innovate and turn ideas into actionable strategies.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-_IU4DG-_o4-zPWqsx1lPg3aqVkSPtaF1Eg&s",
    },
    {
      title: "Digital Learning & Training",
      description:
        "Customized training programs to upskill your team for professional growth.",
      image:
        "https://png.pngtree.com/png-vector/20210720/ourmid/pngtree-e-learning-online-png-image_3626109.jpg",
    },
    {
      title: "Security Solutions",
      description:
        "Advanced security measures to protect your digital assets from cyber threats.",
      image:
        "https://png.pngtree.com/png-clipart/20190905/ourmid/pngtree-creative-hand-painted-network-security-logo-png-image_1722805.jpg",
    },
    {
      title: "Process Automation",
      description:
        "Intelligent automation to streamline workflows, reduce errors, and boost efficiency.",
      image:
        "https://png.pngtree.com/png-vector/20220801/ourmid/pngtree-d-robotic-process-automation-for-business-or-technology-concept-rpa-with-png-image_6092336.png",
    },
  ];

  return (
    <section className="services">
      <motion.h2
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
         Services
      </motion.h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <motion.div
            key={index}
            className="service-item"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default Services;