import React from "react";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Testimonials() {
  const testimonials = [
    {
      quote: "Quixent blasted our tech into hyperspace—mind-blowing!",
      author: "Alex Star, Tech Guru"
    },
    {
      quote: "Their AI solutions transformed our business overnight!",
      author: "Sarah Moon, CEO"
    },
    {
      quote: "Innovative, reliable, and out of this world!",
      author: "John Orbit, Developer"
    }
  ];

  return (
    <section className="testimonials" id="testimonials">
      <h2>Client Raves</h2>
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        transitionTime={600}
        className="testimonials-carousel"
      >
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="testimonial-item"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p>"{testimonial.quote}"</p>
            <span>- {testimonial.author}</span>
          </motion.div>
        ))}
      </Carousel>
    </section>
  );
}

export default Testimonials;