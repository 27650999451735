import React from "react";
import { motion } from "framer-motion";
import { FaTrophy } from "react-icons/fa";

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          We offer IT solutions for your Business
        </motion.h1>
        <p>
          Quixent provides innovative software solutions, expert consulting, and
          payroll management to empower businesses. From custom software and
          digital transformation to seamless payroll processing, our experienced
          team ensures efficiency, scalability, and growth. Partner with us to
          unlock your business’s full potential!
        </p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="explore-button"
        >
          Explore Now <span>→</span>
        </motion.button>
        <div className="hero-badges">
          <motion.div
            className="badge experience"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <FaTrophy className="badge-icon" />
            <span>3+ Years of Experience</span>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Hero;